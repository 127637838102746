<template>
  <v-app class="app-bg">
    <v-main>
      <router-view></router-view>
      <DisabledMedicalAddon></DisabledMedicalAddon>
    </v-main>
  </v-app>
</template>

<script>
import store from "@/store/index";
import ResourcesService from "@/services/resources.service";
import { mapGetters } from "vuex";

export default {
  components: {
    EnableMedicalAddonView: () => import("./EnableMedicalAddonView.vue"),
    DisabledMedicalAddon: () => import("@/components/popups/DisabledMedicalAddon")
  },
  computed: {
    ...mapGetters("ui", ["enabledMedicalAddon"]),
  },
  methods: {
    handleLogout(event) {
      if (
        event.storageArea === sessionStorage &&
        event.key === "access_token" &&
        event.newValue !== event.oldValue &&
        event.oldValue
      ) {
        store.dispatch("ui/reset");
        store.dispatch("autosave/reset");
        this.$router
          .push({
            name: "/",
            query: { redirect: this.$route.fullPath },
          })
          .catch((err) => console.log(err));
      }
    },
  },
  mounted() {
    window.addEventListener("storage", this.handleLogout);
  },
  beforeDestroy() {
    window.removeEventListener("storage", this.handleLogout);
  },
  created() {
    localStorage.removeItem("BACK");
    ResourcesService.check();
  },
};
</script>